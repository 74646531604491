<!--
 * @Descripttion : 菜单管理
 * @version      : 
 * @Author       : ml
 * @Date         : 2020-08-28 20:25:24
 * @LastEditors  : ml
 * @LastEditTime : 2020-09-10 10:27:17
-->
<template>
  <div>
    <div style="padding:10px 0;">
      <a-button type="primary" icon="plus" @click="addMenuItem" v-if="permissions.indexOf('sys:menu:add')>-1" />
    </div>

    <a-table :columns="columns" bordered :data-source="data" :pagination="false" :loading="loading" :rowKey='record=>record.id' size="middle">
      <template slot="operation" slot-scope="record">
        <a-space>
          <a-button type="primary" size="small" icon="edit" v-if="permissions.indexOf('sys:menu:edit')>-1" @click="editMenuItem(record)" />
          <a-button type="danger" size="small" icon="delete" v-if="permissions.indexOf('sys:menu:del')>-1" @click="delMenuItem(record)" />
        </a-space>
      </template>
      <span slot="cssoperation" slot-scope="record">
        <template v-if="record.css">
          <a-icon :type="record.css" />
        </template>
      </span>
    </a-table>

    <!-- 编辑菜单 -->
    <a-drawer :title="title" :width="350" :visible="visibleMenu" @close="onClose">
      <a-form-model ref="ruleForm" :model="formPersmission" :rules="rulesPersmission">
        <a-form-model-item label="上级菜单" prop="parentId">
          <a-select v-model="formPersmission.parentId">
            <template v-for="(item,index) in parentArray">
              <a-select-option :value="item.id" :key="index">
                {{item.name}}
              </a-select-option>
            </template>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="名称" prop="name">
          <a-input v-model="formPersmission.name" placeholder="请输入名称" />
        </a-form-model-item>
        <a-form-model-item label="样式" prop="css">
          <div v-if="formPersmission.css">
            <a-icon :type="formPersmission.css" />
          </div>
          <a-button type="primary" @click="showChildrenDrawer">
            选择样式
          </a-button>
        </a-form-model-item>
        <a-form-model-item label="链接" prop="href">
          <a-input v-model="formPersmission.href" placeholder="请输入链接" />
        </a-form-model-item>
        <a-form-model-item label="类型" prop="type">
          <a-radio-group v-model="formPersmission.type">
            <a-radio :value="1">
              菜单
            </a-radio>
            <a-radio :value="2">
              按钮
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="权限" prop="permission">
          <a-input v-model="formPersmission.permission" placeholder="请输入权限" />
        </a-form-model-item>
        <a-form-model-item label="排序" prop="sort">
          <a-input v-model="formPersmission.sort" placeholder="请输入邮箱" />
        </a-form-model-item>
        <div class="ml_drawer_footer">
          <a-button @click="onClose">
            取消
          </a-button>
          <a-button style="margin-left: 10px" type="primary" @click="onSubmit">
            确定
          </a-button>
        </div>
      </a-form-model>

      <a-drawer title="样式选择" width="420" :closable="false" :visible="childrenDrawer" @close="onChildrenDrawerClose">
        <a-collapse accordion>
          <template v-for="(item,index) in iconList">
            <a-collapse-panel :key="index" :header="item.title">
              <div class="icons-list">
                <template v-for="(items,indexs) in item.list">
                  <a-icon :type="items" @click="selectIcon(items)" :key="indexs" />
                </template>
              </div>
            </a-collapse-panel>
          </template>
        </a-collapse>
      </a-drawer>
    </a-drawer>
  </div>
</template>
<script>
import {
  delPermission,
  getPermission,
  addPermission,
  updatePermission,
  getPermissionsList,
  getPrarntPermission
} from "@/api/system";

import { mapState } from "vuex";

import { iconList } from "@/config/icon";

const columns = [
  { title: "名称", dataIndex: "name" },
  { title: "id", dataIndex: "id", align: "center" },
  { title: "链接", dataIndex: "href", align: "center" },
  {
    title: "样式",
    scopedSlots: { customRender: "cssoperation" },
    align: "center"
  },
  { title: "权限", dataIndex: "permission", align: "center" },
  { title: "排序", dataIndex: "sort", align: "center" },
  {
    title: "操作",
    scopedSlots: { customRender: "operation" },
    align: "center"
  }
];

export default {
  name: "menuManage",
  data() {
    return {
      data: [],
      columns,
      baseData: [],
      visibleMenu: false,
      formPersmission: {
        parentId: 0,
        name: "",
        css: "",
        href: "",
        type: 1,
        permission: "",
        sort: 100,
        child: null,
        json: true
      },
      rulesPersmission: {
        name: [{ required: true, message: "请输入名称!" }]
      },
      parentArray: [],

      childrenDrawer: false,
      iconList: iconList,
      title: "",
      loading: false
    };
  },

  computed: {
    ...mapState({
      permissions: state => state.userStore.permissions
    })
  },
  created() {
    this.getMenuList();
  },
  methods: {
    getMenuList() {
      const that = this;
      that.data = [];
      that.loading = true;
      getPermissionsList({
        _: new Date().getTime()
      })
        .then(res => {
          that.loading = false;
          that.baseData = res.data;
          that.initChildren();
        })
        .catch(err => {
          console.log(err);
          that.loading = false;
        });
    },
    initChildren() {
      // 菜单数据处理
      const that = this;
      that.baseData.forEach(element => {
        if (element.parentId === 0) {
          let _children = that.getChildren(element.id);
          if (_children.length > 0) {
            that.data.push({
              id: element.id,
              name: element.name,
              css: element.css,
              href: element.href,
              permission: element.permission,
              sort: element.sort,
              type: element.type,
              parentId: element.parentId,
              children: _children
            });
          } else {
            that.data.push({
              id: element.id,
              name: element.name,
              css: element.css,
              href: element.href,
              permission: element.permission,
              sort: element.sort,
              type: element.type,
              parentId: element.parentId
            });
          }
        }
      });
    },
    getChildren(id) {
      const that = this;
      let child = [];
      that.baseData.forEach(element => {
        if (element.parentId == id) {
          let _children = that.getChildren(element.id);
          if (_children.length > 0) {
            child.push({
              id: element.id,
              name: element.name,
              css: element.css,
              href: element.href,
              permission: element.permission,
              sort: element.sort,
              type: element.type,
              parentId: element.parentId,
              children: _children
            });
          } else {
            child.push({
              id: element.id,
              name: element.name,
              css: element.css,
              href: element.href,
              permission: element.permission,
              sort: element.sort,
              type: element.type,
              parentId: element.parentId
            });
          }
        }
      });
      return child;
    },
    delMenuItem(e) {
      // 删除菜单
      const that = this;
      that.$confirm({
        title: "是否确认删除该菜单?",
        okType: "danger",
        okText: "确定",
        cancelText: "取消",
        centered: true,
        onOk() {
          delPermission(e.id).then(() => {
            that.$message.success({
              content: "删除成功！",
              duration: 1,
              onClose: () => {
                that.getMenuList();
              }
            });
          });
        },
        onCancel() {
          console.log("Cancel");
        }
      });
    },
    addMenuItem() {
      // 新增菜单
      const that = this;
      that.title = "新增菜单";
      that.visibleMenu = true;
      that.$nextTick(() => {
        that.$refs.ruleForm.resetFields();
      });
      that.formPersmission.id = "";
      that.parentArray = [
        {
          id: 0,
          name: "root"
        }
      ];
      getPrarntPermission({
        _: new Date().getTime()
      }).then(res => {
        let _parentArray = [];
        res.data.forEach(element => {
          if (element.type === 1) {
            _parentArray.push(element);
          }
        });
        that.parentArray = that.parentArray.concat(_parentArray);
      });
    },
    editMenuItem(e) {
      // 编辑菜单
      const that = this;
      that.visibleMenu = true;
      that.$nextTick(() => {
        that.$refs.ruleForm.resetFields();
      });
      that.title = "编辑菜单";
      that.parentArray = [
        {
          id: 0,
          name: "root"
        }
      ];
      getPrarntPermission({
        _: new Date().getTime()
      }).then(res => {
        that.parentArray = that.parentArray.concat(res.data);
        that.getPersionItem(e.id);
      });
    },
    getPersionItem(id) {
      const that = this;
      getPermission(id, {
        _: new Date().getTime()
      }).then(res => {
        that.formPersmission = res.data;
      });
    },
    onClose() {
      this.visibleMenu = false;
    },
    onSubmit() {
      const that = this;
      that.$refs.ruleForm.validate(valid => {
        if (valid) {
          that.formPersmission["json"] = true;
          if (that.formPersmission.id) {
            updatePermission(that.formPersmission).then(() => {
              that.$message.success({
                content: "修改成功！",
                duration: 1,
                onClose: () => {
                  that.visibleMenu = false;
                  that.getMenuList();
                }
              });
            });
          } else {
            addPermission(that.formPersmission).then(() => {
              that.$message.success({
                content: "添加成功！",
                duration: 1,
                onClose: () => {
                  that.visibleMenu = false;
                  that.getMenuList();
                }
              });
            });
          }
        }
      });
    },
    showChildrenDrawer() {
      this.childrenDrawer = true;
    },
    onChildrenDrawerClose() {
      this.childrenDrawer = false;
    },
    selectIcon(icon) {
      this.formPersmission.css = icon;
      this.childrenDrawer = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.icons-list {
  .anticon {
    margin: 0 15px 20px 0;
    font-size: 20px;
    cursor: pointer;
  }
}
</style>